import "./App.scss";
import "./AppResopnsive.scss";
import NavbarComponent from "./components/navbar/navbar.jsx";
import HomeComponent from "./components/home/home.jsx";
import FooterComponent from "./components/footer/footer";
import ProductsAndServicesComponent from "./components/productsAndServices/productsAndServices";
import QualityControlComponent from "./components/qualityControl/qualityControl";
import QualityStandardComponent from "./components/qualityStandard/qualityStandard";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import React from "react";
function App() {
  return (
    <BrowserRouter>
      <NavbarComponent />
      <Routes>
        <Route path="/" element={<HomeComponent />} />
        <Route
          path="/products-and-services"
          element={<ProductsAndServicesComponent />}
        />
        <Route path="/quality-control" element={<QualityControlComponent />} />
        <Route
          path="/quality-standard"
          element={<QualityStandardComponent />}
        />
      </Routes>
      <footer id="contact">
        <FooterComponent />
      </footer>
    </BrowserRouter>
  );
}

export default App;
