// import avatar2 from "../../images/chinese.png";
// import avatar3 from "../../images/doctor.png";
// import avatar4 from "../../images/graphic-designer.png";
import companyImg from "../../images/company-edit.png";
import locationImg from "../../images/location.png";
import facilityImg from "../../images/facility.png";

import "./home.scss";
import "./homeResponsive/sloganResponsive.scss";
import "./homeResponsive/profileResponsive.scss";
import "./homeResponsive/historyResponsive.scss";
import React, { useState } from "react";
import { motion } from "framer-motion";

function HomeComponent() {
  const [showIntroduction, setShowIntroduction] = useState(true);

  const locationFacilityList = [
    {
      title: "Location",
      detail: "Bangkok, Thailand",
      image: locationImg,
    },
    {
      title: "Facility",
      detail: "Plant size / Facility",
      image: facilityImg,
    },
  ];

  // const employeeList = [
  //   { people: "24", type: "Office worker" },
  //   { people: "160", type: "Factory worker" },
  //   { people: "160", type: "Outsource worker" },
  // ];

  const productionCapacityList = [
    { product: "2", quantity: "842,400" },
    { product: "3", quantity: "93,600" },
  ];

  // const secondaryChartList = [
  //   { name: "Name-002", position: "Position-002", image: avatar2 },
  //   { name: "Name-003", position: "Position-003", image: avatar3 },
  //   { name: "Name-004", position: "Position-004", image: avatar4 },
  // ];

  const setIntroduction = () => {
    setShowIntroduction(!showIntroduction);
  };

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
    },
  };

  const item = {
    hidden: { x: -20, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
    },
  };

  const head = {
    hidden: { x: -300, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
    },
  };

  return (
    <div className="home">
      {/* -----------Company Introduction---------- */}
      <div className="slogan">
        <motion.h1
          variants={head}
          transition={{
            duration: 1.5,
          }}
          initial="hidden"
          whileInView="visible"
        >
          World’s <span>Best Cylinder Manufacturer</span>
        </motion.h1>

        {showIntroduction ? (
          <motion.div
            initial={{ x: 300, opacity: 0 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{
              duration: 0.8,
              delay: 0.5,
            }}
            className="introduction"
          >
            <h4>Company Introduction</h4>
            <p>
              We specialized in making welding steel low pressure containers or
              cylinders for LPG. Our range includes consumer and industrial
              usage, range from 0.8 to 113 liters water capacity. All cylinders
              are produce with quality standard in accordance to local and
              international law and regulations such as Thailand Standard (TIS),
              ISO Standard (International Standard), EN, BS, and TPED (EU), AS
              Standard (Australia) and DOT specifications.
            </p>
            <p>
              With over 40 years of experience serving domestic and
              international market, we are ready to support you LPG business and
              to provide you with the best service for your LP gas containers.
              At Linh Gas, we are dedicated to continue setting the global
              standard for sustainability, service and reliability.
            </p>
            <button
              className={showIntroduction ? null : "intro-button"}
              onClick={setIntroduction}
            >
              Hide Company Introduction
            </button>
          </motion.div>
        ) : (
          <button className="intro-button" onClick={setIntroduction}>
            More Company Introduction
          </button>
        )}
      </div>

      {/* -----------Company Profile---------- */}
      <div className="profile">
        <motion.div
          variants={container}
          transition={{
            delay: 0.5,
          }}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="location-facility-content home-margin"
        >
          {locationFacilityList.map((list, idx) => (
            <div className="location-facility-list-style" key={idx}>
              {list.title === "Location" ? (
                <a
                  href="https://www.google.com/maps/place/%E0%B8%9A%E0%B8%88%E0%B8%81.+%E0%B8%8A%E0%B8%B7%E0%B9%88%E0%B8%99%E0%B8%A8%E0%B8%B4%E0%B8%A3%E0%B8%B4/@13.6451523,100.7107689,17z/data=!3m1!4b1!4m6!3m5!1s0x311d5dd712fb7fd3:0x8602544a0ad586dd!8m2!3d13.6451523!4d100.7133492!16s%2Fg%2F1th5x7q9?entry=ttu"
                  target="_blank"
                >
                  <div className="detail">
                    <h4 className="home-head">{list.title}</h4>
                    <p>{list.detail}</p>
                  </div>
                  <div className="image">
                    <img src={list.image} alt="" />
                  </div>
                </a>
              ) : (
                <>
                  <div className="detail">
                    <h4 className="home-head">{list.title}</h4>
                    <p>{list.detail}</p>
                  </div>
                  <div className="image">
                    <img src={list.image} alt="" />
                  </div>
                </>
              )}
            </div>
          ))}
        </motion.div>

        {/* <div className="employee home-margin">
          <h4 className="home-head">Employee</h4>
          <motion.div
            variants={container}
            transition={{
              delay: 0.3,
              duration: 1,
            }}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="employee-content"
          >
            {employeeList.map((list, idx) => (
              <div className="employee-list-style" key={idx}>
                <div className="list">
                  <h4>{list.people}</h4>
                  <h5>{list.type}</h5>
                </div>
              </div>
            ))}
          </motion.div>
        </div> */}

        <div className="productions home-margin">
          <h4 className="home-head">Production Capacity</h4>
          {productionCapacityList.map((list, idx) => (
            <motion.div
              variants={container}
              transition={{
                duration: 1,
              }}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="production-list-style"
              key={idx}
            >
              <h5>
                {list.product} <br /> PIECES
              </h5>
              <p>
                <span>{list.quantity}</span>
                <br />
                cylinders per annum
              </p>
            </motion.div>
          ))}
        </div>

        {/* <div className="chart home-margin">
          <h4 className="home-head">Organization Chart</h4>
          <motion.div
            variants={container}
            transition={{
              duration: 1,
            }}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="primary-chart"
          >
            <div className="chart-card">
              <img src={avatar1} alt="Avatar" />
              <div className="chart-container">
                <h4>Name-001</h4>
                <p>Position-001</p>
              </div>
            </div>
          </motion.div>
          <motion.div
            variants={container}
            transition={{
              duration: 1,
            }}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="secondary-chart"
          >
            {secondaryChartList.map((list, idx) => (
              <div className="chart-card" key={idx}>
                <img src={list.image} alt="Avatar" />
                <div className="chart-container">
                  <h4>{list.name}</h4>
                  <p>{list.position}</p>
                </div>
              </div>
            ))}
          </motion.div>
        </div> */}
      </div>

      <div className="history">
        <h4 className="home-head">History</h4>
        <motion.div
          variants={item}
          initial="hidden"
          whileInView="visible"
          className="history-content"
        >
          <img src={companyImg} alt="" />
          <p>
            We grew from a family size business of manufacturing low pressure
            container for the country to be one of the strongest supplier of LPG
            cylinder all over the globe. Our development and achievement are
            result of over 40 years of knowhow and experience in making Pressure
            containers, with the determination to only improve and be the most
            trusted supplier for our customers’ business.
          </p>
        </motion.div>
      </div>
    </div>
  );
}

export default HomeComponent;
