import "./qualityStandard.scss";
import "./qualityStandardResponsive.scss";
import "../../App"
import { motion } from "framer-motion";

function QualityStandardComponent() {
  const qualityStandardList = [
    { title: "TIS2543", content: "Thailand Industrial Standard" },
    {
      title: "ISO9001",
      content: "International Organization for Standardization",
    },
    { title: "EN", content: "European Standard" },
    { title: "SANS", content: "South African National Standard" },
    { title: "JS", content: "Jamaican Standard" },
    { title: "SLSI", content: "Sri Lanka Standard Institution" },
  ];

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.5,
        staggerChildren: 0.5,
      },
    },
  };

  const item = {
    hidden: { x: -100, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
    },
  };

  return (
    <div className="quality-standard">
      <h2>Quality Standard</h2>
      <motion.div
        variants={container}
        initial="hidden"
        whileInView="visible"
        className="quality-standard-content"
      >
        {qualityStandardList.map((list, idx) => (
          <motion.div variants={item} className="square" key={idx}>
            <div className="circle">
              <h4>
                <p>{list.title}</p>
              </h4>
            </div>
            <div className="triagle"></div>
            <p className="text">{list.content}</p>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
}

export default QualityStandardComponent;
