import productImg from "../../images/product-edit.png";
import serviceImg from "../../images/Manufacturer.1-edit.png";
import "../../App"
import "./productsAndServices.scss";
import "./productsAndServicesResponsive.scss"
import { motion } from "framer-motion";

function ProductsAndServicesComponent() {
  const productList = [
    { productName: "2", volume: "0.8 liters to 43 liters" },
    { productName: "3", volume: "43 liters to 118 liters" },
  ];

  return (
    <div className="products-services">
      <h2>Products And Services</h2>
      <motion.div
        initial={{ x: 300, opacity: 0 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{
          duration: 1,
          delay: 0.5,
        }}
        className="product-content"
      >
        <img src={productImg} alt="" />
        {productList.map((list, idx) => (
          <div className="product-list" key={idx}>
            <h3>
              {list.productName} PIECES
            </h3>
            <p>Range from <span>{list.volume}</span></p>
          </div>
        ))}
      </motion.div>
      <motion.div
        initial={{ x: -300, opacity: 0 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{
          duration: 1,
          delay: 0.5,
        }}
        className="service-content"
      >
        <img src={serviceImg} alt="" />
        <div className="service-list">
          <h3>Reconditioning</h3>
          <p>Refurbish 5 -10 years cylinder, up to required standard</p>
        </div>
      </motion.div>
    </div>
  );
}

export default ProductsAndServicesComponent;
