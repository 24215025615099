import "./footer.scss";
import "./footerResponsive.scss";
import "../../App";

function FooterComponent() {
  return (
    <div className="footer">
      <div className="footer-content">
        <h4>Contact Us</h4>
        <ul>
          <li>
            <a
              href="https://www.google.com/maps/place/%E0%B8%9A%E0%B8%88%E0%B8%81.+%E0%B8%8A%E0%B8%B7%E0%B9%88%E0%B8%99%E0%B8%A8%E0%B8%B4%E0%B8%A3%E0%B8%B4/@13.6451523,100.7107689,17z/data=!3m1!4b1!4m6!3m5!1s0x311d5dd712fb7fd3:0x8602544a0ad586dd!8m2!3d13.6451523!4d100.7133492!16s%2Fg%2F1th5x7q9?entry=ttu"
              target="_blank"
            >
              Linh Gas Cylinder Co., Ltd.
            </a>
          </li>
          <li>
            No.88 Moo 12 Pracharajudith Road (Kingkaew) Rajatheva, Bangplee,
            Samutprakarn 10540, Thailand
          </li>
          <li>Email : info@linhgas.com</li>
          <li>Phone : +66 (0) 2312 4606 to 9</li>
          <li>Mobile: +66 (0) 88 680 8222</li>
          <li>Fax: +66 (0) 2231 2435 9</li>
        </ul>
      </div>
      <div className="footer-content">
        <h4>Work with Us</h4>
        <ul>
          <li>Contact Our HR department</li>
          <li>02-120-9999 ext. 3007</li>
        </ul>
        <h4>Direct Sales</h4>
        <ul>
          <li>+66 97 274 7761</li>
          <li>+66 88 680 8222</li>
        </ul>
      </div>
    </div>
  );
}

export default FooterComponent;
