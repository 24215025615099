import logo from "../../images/logo-edit.png";
import hamburger from "../../images/burger-bar2.png";
import cross from "../../images/remove.png";

import "./navbar.scss";
import "./navbarResponsive.scss";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function NavbarComponent() {
  let navigate = useNavigate();
  const [showHmburgerbar, setShowHmburgerbar] = useState(true);

  return (
    <div className="menu">
      <div className="normal-bar">
        <div className="menu-image">
          <a onClick={()=>{navigate("/")}}>
            <img src={logo} height="55px" alt=""/>
          </a>
        </div>
        <div className="menu-list">
          <ul>
            <li>
              <a onClick={()=>{navigate("/")}}>Home</a>
            </li>
            <li>
              <a onClick={()=>{navigate("/products-and-services")}}>Products and Services</a>
            </li>
            <li>
              <a onClick={()=>{navigate("/quality-standard")}}>Quality Standard</a>
            </li>
            <li>
              <a onClick={()=>{navigate("/quality-control")}}>Quality Control</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </div>
      </div>

      <div id={ showHmburgerbar ? "hamburger-menu" : "hamburger-menu-change"}>
        <div className="menu-image">
          <a onClick={()=>{navigate("/")}}>
            <img src={logo} height="55px" alt=""/>
          </a>
        </div>
        <div className="hamburger-content">
          <img
            className="hamburger-image"
            src={showHmburgerbar ? hamburger : cross}
            alt=""
            onClick={() => setShowHmburgerbar(!showHmburgerbar)}
          />
          <div className="nav" id={showHmburgerbar ? "nav" : "change"}>
            <ul>
              <li>
                <a onClick={()=>{navigate("/")}}>Home</a>
              </li>
              <li>
                <a onClick={()=>{navigate("/products-and-services")}}>Products and Services</a>
              </li>
              <li>
                <a onClick={()=>{navigate("/quality-standard")}}>Quality Standard</a>
              </li>
              <li>
                <a onClick={()=>{navigate("/quality-control")}}>Quality Control</a>
              </li>
              <li>
                <a href="#contact">Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        className="menu-bg"
        id={showHmburgerbar ? "menu-bg" : "change-bg"}
      ></div>
    </div>
  );
}

export default NavbarComponent;
