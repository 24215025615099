import "./qualityControl.scss";
import "./qualityControlResponsive.scss";
import "../../App"
import React, { useState } from "react";
import { motion } from "framer-motion";

function QualityControlComponent() {
  const [showTH, setshowTH] = useState(true);
  const qualityControlList = [
    { contentEN: "Hydrostatic Test", contentTH: "การทดสอบความดันไฮโดรลิก" },
    { contentEN: "Air Leak test", contentTH: "การตรวจสอบการรั่วซึม" },
    { contentEN: "Burst Test", contentTH: "การทดสอบความดันระเบิด" },
    {
      contentEN: "Permanent Volumetric Expansion",
      contentTH: "การทดสอบการขยายตัวถาวร",
    },
    { contentEN: "Radiographic Test (RT)", contentTH: "การทดสอบงานเชื่อมด้วยรังสี" },
    { contentEN: "Macro-Etch Test", contentTH: "การทดสอบระดับมหภาค" },
    { contentEN: "Hardness Test", contentTH: "การทดสอบความแข็ง" },
    {
      contentEN: "Micro Structure Test",
      contentTH: "การตรวจสอบโครงสร้างระดับจุลภาค",
    },
    { contentEN: "Yield and Tensile Test", contentTH: "การทดสอบแรงดึง" },
    { contentEN: "Bending Test", contentTH: "การทดสอบดัดโค้ง" },
    { contentEN: "Thread Projector Test", contentTH: "การตรวจสอบโครงร่างเกลียว" },
  ];

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.5,
        staggerChildren: 0.3,
      },
    },
  };

  const item = {
    hidden: { x: -50, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
    },
  };

  return (
    <div className="quality-control">
      <h2>Quality Control</h2>
      <button onClick={() => setshowTH(!showTH)}>{showTH ? "TH" : "EN"}</button>
      <motion.div
        variants={container}
        initial="hidden"
        whileInView="visible"
        className="quality-control-content"
      >
        {qualityControlList.map((list, idx) => (
          <motion.div variants={item} className="quality-control-list-style" key={idx}>
            {showTH ? <h4>{list.contentEN}</h4> : <p>{list.contentTH}</p>}
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
}

export default QualityControlComponent;
